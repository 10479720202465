import { Pipe, PipeTransform } from '@angular/core';
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  sub,
  isDate,
  isAfter,
  isValid,
} from 'date-fns';

@Pipe({
  name: 'ago',
})
export class AgoPipe implements PipeTransform {
  public transform(value: Date | string | number, maxParts = 1, suffix = 'ago', useShort = false): string {
    if (!value) {
      return '—';
    }
    let asDate = value;
    if (!isDate(value)) {
      asDate = new Date(value);
    }
    return preciseDiffToHuman(asDate as Date, maxParts, suffix, useShort);
  }
}

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  month: differenceInMonths,
};
type possibleParts = keyof typeof differenceInFn;

interface PreciseDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  months: number;
}

const preciseDifference = (
  date: Date,
  parts: possibleParts[] = ['month', 'day', 'hour', 'minute'],
): PreciseDiff | null => {
  let now = new Date();
  if (isAfter(date, now)) {
    return null;
  }
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
  };

  parts.forEach((part, i) => {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(now, date);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const subtractionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to subtract from "now" until we get down to the date
        now = sub(now, subtractionDuration);
      }
    }
  });
  return response;
};

const dateOrder: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = {
  months: 'mo',
  minutes: 'm',
  hours: 'h',
  days: 'd',
};

const preciseDiffToHuman = (date: Date, maxParts: number, suffix: string, useShort: boolean): string => {
  let response = '—';
  if (isValid(date)) {
    const preciseDiff = preciseDifference(date);
    if (preciseDiff) {
      const result = [];
      let dateOrderIndex = 0;
      while (dateOrderIndex < dateOrder.length && result.length < maxParts) {
        const key = dateOrder[dateOrderIndex];
        if (preciseDiff[key] > 0) {
          const keyName = substitutes[key] && useShort ? substitutes[key] : key;
          if (useShort) {
            result.push(`${preciseDiff[key]}${removePluralIfNeeded(preciseDiff[key], keyName)}`);
          } else {
            result.push(`${preciseDiff[key]} ${removePluralIfNeeded(preciseDiff[key], keyName)}`);
          }
        }
        dateOrderIndex++;
      }
      response = 'just now';
      if (result.length) {
        response = `${result.join(' ')} ${suffix}`;
      }
    }
  }
  return response;
};

const removePluralIfNeeded = (count: number, pluralString: string) => {
  if (count !== 1 || !pluralString.endsWith('s')) {
    return pluralString;
  }
  return pluralString.substring(0, pluralString.length - 1);
};
