import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { AuthService, UserInfo } from '../services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'tr-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  public userInfo$: Observable<UserInfo>;

  constructor(private authService: AuthService) {
    this.userInfo$ = this.authService.userInfo$;
  }

  public logout() {
    this.authService.logout();
  }
}
