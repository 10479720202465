import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';
import { combineLatestWith, filter, take, takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from '../shared/components/destroyable.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NetworkableDestroyableComponent } from '../shared/components/networkable-destroyable.component';
import { SnackBarService } from '../shared/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs';

interface FormValue {
  phoneNumber: string;
  driverName: string;
  password: string;
  email: string;
  mcNumber?: string;
  referralCode?: string;
}

@Component({
  selector: 'tr-landing',
  templateUrl: './landing.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent extends NetworkableDestroyableComponent {
  @ViewChild('signupTemplate') public signupModal: TemplateRef<any>;
  public form: FormGroup;
  public phoneNumberControl: FormControl;
  public driverCountControl;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private snackbar: SnackBarService,
  ) {
    super();
    this.authService.isLoggedIn$
      .pipe(
        takeUntil(this.destroy$$),
        filter((v) => !!v),
      )
      .subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });
    this.phoneNumberControl = this.fb.control(null, [Validators.required]);
    this.driverCountControl = this.fb.control('10', []);
    this.form = this.fb.group({
      driverName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mcNumber: [null, []],
      referralCode: [null, []],
    });

    this.phoneNumberControl.valueChanges
      .pipe(
        combineLatestWith(this.phoneNumberControl.statusChanges),
        filter(([_, status]) => status === 'VALID'),
        switchMap(([value, _]) => {
          return this.authService.phoneExists$(value);
        }),
        takeUntil(this.destroy$$),
      )
      .subscribe((value) => {
        if (value) {
          this.matDialog.closeAll();
          this.snackbar.showMessage('User already exists, please login');
          this.router.navigateByUrl('/auth');
        }
      });
  }

  public promptSignup() {
    this.matDialog.open(this.signupModal, {
      panelClass: ['md:w-1/2'],
      closeOnNavigation: true,
    });
  }

  public async signup() {
    if (this.networkActive$$.value || this.form.invalid) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const value = this.form.value as FormValue;
      const signedUp = await this.authService.signup(
        value.driverName,
        this.phoneNumberControl.value,
        value.password,
        value.email,
        value.mcNumber,
        value.referralCode,
      );
      if (signedUp) {
        this.matDialog.closeAll();
        this.snackbar.showMessage("You've signed up for the freedom program! Redirecting to login...");
        this.router.navigateByUrl('/auth');
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.error.isClientError) {
          this.snackbar.showError(error.error.messageToUser);
        } else {
          this.snackbar.showError('Unexpected Error Occurred Processing Request');
        }
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public copyDownload() {
    navigator.clipboard.writeText('https://link.5f.tours/install');
    this.snackbar.showMessage('Link Copied');
  }
}
