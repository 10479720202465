<mat-form-field class="w-full">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>Search For Address</mat-label>
  <mat-select [disabled]="disabled">
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Search"
        noEntriesFoundLabel="No match found"
        [formControl]="addressSearch"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      (onSelectionChange)="selectAddress(address)"
      *ngFor="let address of addresses$ | async; trackBy: addresses$ | trackByKey: 'id'"
      [value]="address"
      style="height: unset; min-height: 42px; line-height: 24px; font-size: 14px"
    >
      <div class="break-words whitespace-normal">
        {{ address.address.label }}
      </div>
    </mat-option>
  </mat-select>
  <mat-hint>Autofills address and coordinate fields</mat-hint>
</mat-form-field>
