import { Pipe, PipeTransform } from '@angular/core';
import { add, isDate, isValid } from 'date-fns';

@Pipe({
  name: 'addMinutes',
})
export class AddMinutesPipe implements PipeTransform {
  public transform(value: Date | number | string, minutes: number): Date {
    if (!isDate(value)) {
      value = new Date(value);
    }
    if (!isValid(value)) {
      return value as Date;
    }

    return add(value as Date, { minutes });
  }
}
