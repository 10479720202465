<div class="flex flex-col h-full bg-white dark:bg-gray-900">
  <div class="flex flex-col">
    <a [routerLink]="['/']" class="p-2">
      <img src="assets/logos/white.svg" class="hidden dark:block h-5 w-full" alt="logo" />
      <img src="assets/logos/black.svg" class="dark:hidden h-5 w-full" alt="logo" />
    </a>
    <a
      [routerLink]="['/dashboard']"
      class="font-semibold hover:bg-gray-400 dark:hover:bg-zinc-600 p-2 mx-2 mb-1 rounded"
    >
      Dashboard
    </a>
  </div>
  <div class="mt-auto flex flex-1 items-end flex-row justify-between w-full mt-3 p-2">
    <div class="flex flex-col">
      <div class="font-semibold">{{ (userInfo$ | async)?.userName }}</div>
    </div>
    <button mat-flat-button color="warn" (click)="logout()">Logout</button>
  </div>
</div>
