import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'tr-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent {
  public title = 'tour-broker-web';
  public isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }
}
