import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isToday, isTomorrow } from 'date-fns';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return '—';
    }
    if (format) {
      return super.transform(value, format);
    }
    // This is a nullable time from the server
    if (value.constructor === Object) {
      if (!value.valid || !value.time) {
        return '—';
      }
      return super.transform(value.time, standardDateFormat(value.time));
    }

    return super.transform(value, standardDateFormat(value));
  }
}

export const standardDateFormat = (value: any): string => {
  const asDate = new Date(value);
  let formattingText = `h:mmaaaaa'm' M/dd/yyyy`;
  if (isToday(asDate)) {
    formattingText = `h:mmaaaaa'm' 'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `h:mmaaaaa'm' 'Tomorrow'`;
  }
  return formattingText;
};
