import { Pipe, PipeTransform } from '@angular/core';
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'isPhone',
})
export class IsPhonePipe implements PipeTransform {
  public transform(value: string): boolean {
    return isValidPhoneNumber(value, 'US');
  }
}
