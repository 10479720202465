import { Component, ChangeDetectionStrategy, Output, EventEmitter, HostBinding, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { HereLookupResult, HereSearchResult, HereService } from '../../services/here.service';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'tr-address-search',
  templateUrl: './address-search.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent {
  @Input() public disabled = false;
  @Output() private addressResult = new EventEmitter<HereLookupResult>();

  public addressSearch = new FormControl();
  public addresses$: Observable<HereSearchResult[]>;

  constructor(private hereService: HereService) {
    this.addresses$ = this.addressSearch.valueChanges.pipe(
      debounceTime(300),
      switchMap((v) => {
        if (!v || v?.length < 3) {
          return of([] as HereSearchResult[]);
        }
        return this.hereService.searchForAddresses$(v);
      }),
      shareReplay(1),
    );
  }

  public async selectAddress(address: HereSearchResult) {
    const lookup = await lastValueFrom(this.hereService.lookup$(address.id));
    this.addressResult.next(lookup);
  }
}
