import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MapPipe } from './pipes/map.pipe';
import { TrackByKeyPipe } from './pipes/track-by-key.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { StandardTimePipe } from './pipes/standard-time.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { NumberBadgeComponent } from './components/number-badge/number-badge.component';
import { MaterialModule } from './material.module';
import { CurrencyInPenniesPipe } from './pipes/currency-in-pennies.pipe';
import { DashIfNothingPipe } from './pipes/dash-if-nothing.pipe';
import { IsPhonePipe } from './pipes/is-phone.pipe';
import { PageTitleDirective } from './directives/page-title.directive';
import { HighlightTextDirective } from './directives/highlight-text.directive';
import { TooltipModule } from 'ng2-tooltip-directive';
import { RouterModule } from '@angular/router';
import { IsImagePipe } from './pipes/is-image.pipe';
import { CompareWithPipe } from './pipes/compare-with.pipe';
import { CurrencyInputDirective } from './directives/money.directive';
import { NumberInputDirective } from './directives/number-input.directive';
import { RecordCallDirective } from './directives/record-call.directive';
import * as Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { MinutesToHumanPipe } from './pipes/minutes-to-human.pipe';
import { AgoPipe } from './pipes/ago.pipe';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AddMinutesPipe } from './pipes/add-minutes.pipe';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ReactiveFormsModule } from '@angular/forms';

noData(Highcharts);

const tooltipOptions = {
  placement: 'top',
  'show-delay': 200,
  'tooltip-class': 'tooltip-overrides',
};

@NgModule({
  declarations: [
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    MinutesToHumanPipe,
    AgoPipe,
    AddMinutesPipe,
    AddressSearchComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TooltipModule.forRoot(tooltipOptions),
    RouterModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    ReactiveFormsModule,
  ],
  exports: [
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    MinutesToHumanPipe,
    AgoPipe,
    AddMinutesPipe,
    AddressSearchComponent,
  ],
})
export class SharedModule {}
