import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../environments/environment';

export interface UserInfo {
  userId: string;
  userName: string;
  referralCode?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // @ts-ignore
  private userInfo$$ = new BehaviorSubject<UserInfo>(null);
  public userInfo$ = this.userInfo$$.asObservable().pipe(shareReplay(1));
  private httpClientNoInterceptors: HttpClient;
  // @ts-ignore
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public isLoggedIn$ = this.isLoggedIn$$.asObservable().pipe(
    filter((isLoggedIn) => isLoggedIn !== null),
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor(httpBackend: HttpBackend, private logger: NGXLogger, private afAuth: AngularFireAuth) {
    this.httpClientNoInterceptors = new HttpClient(httpBackend);

    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        await this.loadUserInfo();
        const userInfo = await lastValueFrom(this.userInfo$.pipe(take(1)));
        if (!userInfo) {
          this.isLoggedIn$$.next(false);
          this.logout();
          return;
        }
        this.isLoggedIn$$.next(true);
      } else {
        this.isLoggedIn$$.next(false);
      }
    });
  }

  public async loginWithPhone(phoneNumber: string, password: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ customToken: string }>(
          `${environment.api}/v1/login/password`,
          { phoneNumber, password },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async signup(
    driverName: string,
    phoneNumber: string,
    password: string,
    email: string,
    mcNumber?: string,
    referralCode?: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.httpClientNoInterceptors.post(
          `${environment.api}/v1/signup`,
          {
            driverName,
            phoneNumber,
            password,
            email,
            mcNumber,
            referralCode,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return true;
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  public async logout(redirect = true) {
    await this.afAuth.signOut();
    if (redirect) {
      window.location.pathname = '/';
    }
  }

  public async loadUserInfo() {
    const token = await lastValueFrom(
      this.afAuth.idToken.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    try {
      const userInfo = await lastValueFrom(
        this.httpClientNoInterceptors.get<UserInfo>(`${environment.api}/v1/referral/user_info`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
          },
        }),
      );
      this.userInfo$$.next(userInfo);
    } catch (e) {
      this.afAuth.signOut();
    }
  }

  public async loginWithCustomToken(token: string): Promise<boolean> {
    try {
      await this.afAuth.signInWithCustomToken(token);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public phoneExists$(phone: string): Observable<boolean> {
    if (!phone) {
      return of(false);
    }
    return this.httpClientNoInterceptors
      .get(`${environment.api}/v1/phone_exists_2/${phone}`, {
        observe: 'response',
      })
      .pipe(
        map((value) => value.status !== 200),
        catchError((err) => of(true)),
      );
  }
}
