<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    class="w-full lg:w-1/2 xl:w-1/3 bg-white dark:bg-zinc-900 max-h-screen"
    [autoFocus]="false"
  >
    <tr-side-nav (click)="sidenav.close()"></tr-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-screen">
      <div class="h-6 flex bg-white dark:bg-gray-900 shadow z-10" style="min-height: 48px" *ngIf="isLoggedIn$ | async">
        <div class="w-8 flex items-center justify-center">
          <mat-icon class="cursor-pointer" (click)="sidenav.open()">menu</mat-icon>
        </div>
        <div class="flex-1 flex items-center">
          <a [routerLink]="['/dashboard']">
            <img src="assets/logos/white.svg" class="hidden dark:block h-3 w-full" alt="logo" />
            <img src="assets/logos/black.svg" class="dark:hidden h-3 w-full" alt="logo" />
          </a>
        </div>
      </div>
      <div class="flex-1">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
