import { Pipe, PipeTransform } from '@angular/core';

const isImage = /(gif|jpe?g|tiff?|png|webp|bmp|svg)$/;
@Pipe({
  name: 'isImage',
})
export class IsImagePipe implements PipeTransform {
  public transform(value: string): boolean {
    return isImage.test(value);
  }
}
