import { Injectable } from '@angular/core';
import { Observable, pluck } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface HereSearchResult {
  title: string;
  id: string;
  resultType: string;
  localityType: string;
  address: Address;
}

export interface Address {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
}

export interface HereLookupResult {
  title: string;
  id: string;
  resultType: string;
  localityType: string;
  address: Address;
  position: Position;
  mapView: MapView;
}

export interface Address {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
}

export interface MapView {
  west: number;
  south: number;
  east: number;
  north: number;
}

export interface Position {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class HereService {
  constructor(private http: HttpClient) {}

  public searchForAddresses$(search: string): Observable<HereSearchResult[]> {
    return this.http
      .get<{ items: HereSearchResult[] }>(`${environment.api}/v2/external/shipper_load_bid/autocomplete?q=${search}`)
      .pipe(pluck('items'));
  }

  public lookup$(id: string): Observable<HereLookupResult> {
    return this.http
      .get<{ result: HereLookupResult }>(`${environment.api}/v2/external/shipper_load_bid/lookup?hereID=${id}`)
      .pipe(pluck('result'));
  }
}
