<div class="h-screen flex flex-col overflow-x-hidden text-black">
  <div class="bg-white dark:bg-zinc-900 shadow relative z-50 flex flex-row p-2 h-6 items-center w-full justify-between">
    <a href="https://5f.app/otr">
      <img src="assets/big-logo.svg" alt="logo" class="hidden md:block z-10 ml-5" style="margin-bottom: -50px" />
      <img src="assets/logo.svg" alt="logo" class="md:hidden h-4 w-4" />
    </a>
    <div class="flex flex-row items-center gap-x-1 md:gap-x-5">
      <div>
        <a routerLink="/auth" class="bg-yellow-400 p-1 px-2 md:px-4 font-semibold rounded"> Sign In </a>
      </div>
    </div>
  </div>
  <div class="bg-cover bg-center" style="background-image: url('assets/home/background.jpeg')">
    <div class="flex-1 bg-gradient-to-b from-transparent to-black">
      <div class="flex flex-col md:flex-row justify-around items-center p-2 md:p-8 md:h-[70vh] gap-4">
        <div class="flex flex-col gap-y-4 text-white">
          <img src="assets/home/freedom.svg" alt="freedom" />
          <div class="text-5xl font-semibold">Earn money without driving a mile.</div>
          <div class="text-lg">
            Refer Owner Operators to join 5F OTR division and make money for every load they haul.
          </div>
          <div class="flex flex-row gap-x-3 justify-center items-center">
            <a href="https://apps.apple.com/be/app/5f-tours/id1600761001">
              <img src="assets/home/apple.svg" alt="apple" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.fivef.tour"
              ><img alt="Get it on Google Play" src="assets/home/google.png"
            /></a>
          </div>
        </div>
        <div class="bg-white dark:bg-zinc-900 flex flex-col items-center p-3 text-center rounded gap-y-2">
          <div>
            <div class="font-semibold text-3xl dark:text-white">Sign Up</div>
            <div class="text-sm text-gray-600">
              <span>By signing up, I agree to the 5F <a class="underline">terms and conditions.</a></span>
            </div>
          </div>
          <mat-form-field class="w-full">
            <mat-label>Phone Number</mat-label>
            <ngx-mat-intl-tel-input
              [preferredCountries]="['us', 'ca', 'mx']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              format="national"
              name="phoneNumber"
              [required]="true"
              [formControl]="phoneNumberControl"
            ></ngx-mat-intl-tel-input>
          </mat-form-field>
          <button class="bg-yellow-400 p-1 px-2 md:px-4 font-semibold w-full rounded" (click)="promptSignup()">
            Start Earning
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-[#F4F5F8]">
    <div class="flex flex-col md:flex-row justify-around items-center p-2 md:p-8 md:h-[70vh] gap-4">
      <div class="flex flex-col gap-y-4">
        <div class="text-2xl text-center md:text-left md:text-5xl font-semibold">
          Build Your Team. <br />You Earn As They Earn.
        </div>
        <div class="flex flex-row gap-x-3 justify-start items-center">
          <div class="flex-none"><img src="assets/home/qrcode.svg" alt="qr" /></div>
          <div class="text-xl">
            <div class="font-bold uppercase">step one</div>
            <div>Signup for your unique referral code.</div>
          </div>
        </div>
        <div class="flex flex-row gap-x-3 justify-start items-center">
          <div class="flex-none"><img src="assets/home/chat.svg" alt="qr" /></div>
          <div class="text-xl">
            <div class="font-bold uppercase">step two</div>
            <div>Ask Owner Operators to download the 5F OTR app and signup with your code!</div>
            <button class="text-ffYellow font-semibold underline text-normal" (click)="copyDownload()">
              Copy Download Link
            </button>
          </div>
        </div>
        <div class="flex flex-row gap-x-3 justify-start items-center">
          <div class="flex-none"><img src="assets/home/money.svg" alt="qr" /></div>
          <div class="text-xl">
            <div class="font-bold uppercase">step three</div>
            <div>Check your earnings & get paid weekly with the Freedom Plan!*</div>
            <div class="text-sm text-gray-600">*View Freedom Plan <a class="underline">terms and conditions.</a></div>
          </div>
        </div>
      </div>
      <div class="flex-none">
        <img src="assets/home/img.png" alt="phone-1" />
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="p-2 md:p-8 md:h-[70vh]">
      <div class="text-2xl text-center md:text-5xl font-semibold pb-3">Unlimited Earning Potential</div>
      <div class="flex flex-col md:flex-row justify-around items-center gap-4">
        <div class="flex flex-col gap-y-4 items-center md:basis-1/2">
          <div>
            <mat-radio-group class="flex flex-row gap-x-2" [formControl]="driverCountControl">
              <mat-radio-button value="10">10 Drivers</mat-radio-button>
              <mat-radio-button value="25">25 Drivers</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="flex flex-col items-center">
            <img
              src="assets/home/drivers-1.png"
              alt="drivers"
              class="w-2/3"
              *ngIf="driverCountControl.value === '10'"
            />
            <img
              src="assets/home/drivers-2.png"
              alt="drivers"
              class="w-2/3"
              *ngIf="driverCountControl.value === '25'"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-2 md:basis-1/3">
          <div class="w-full">
            <div class="text-center py-2 border-t border-b border-black">
              <span class="font-bold">Your Earnings</span> (% of line-haul)
            </div>
            <div class="flex flex-row justify-evenly w-full pt-2">
              <div>
                <span class="font-bold">Tier 1</span>
                <div>.333%</div>
              </div>
              <div>
                <span class="font-bold">Tier 2</span>
                <div>.083%</div>
              </div>
              <div>
                <span class="font-bold">Tier 3</span>
                <div>.021%</div>
              </div>
            </div>
          </div>
          <div>
            <div class="font-bold">Tier 1 (Direct) Referrals</div>
            If you refer 10 drivers, you will make 0.333% of every dollar each of those drivers make on 5F in OTR.
          </div>
          <div>
            <div class="font-bold">Tier 2</div>
            If they each hire another 10 drivers you will make .083% of every dollar they make on 5F in OTR.
          </div>
          <div>
            <div class="font-bold">Tier 3</div>
            If your Tier 2 referrals each hire another 10 drivers you will make .021% of every dollar they make on 5F in
            OTR!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-ffYellow">
    <div class="p-2 md:p-4 flex flex-col items-center justify-center gap-y-2">
      <div class="font-semibold text-3xl">Start Earning Today</div>
      <div class="flex flex-row flex-wrap items-center">
        <mat-form-field class="bg-white px-2 w-full">
          <mat-label>Phone Number</mat-label>
          <ngx-mat-intl-tel-input
            [preferredCountries]="['us', 'ca', 'mx']"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            format="national"
            name="phoneNumber"
            [required]="true"
            [formControl]="phoneNumberControl"
          ></ngx-mat-intl-tel-input>
        </mat-form-field>
        <button class="bg-black p-2 font-semibold text-white w-full" (click)="promptSignup()">Start Earning</button>
      </div>
    </div>
  </div>
</div>

<ng-template #signupTemplate>
  <div mat-dialog-title>Signup</div>
  <mat-dialog-content>
    <form [formGroup]="form" class="grid grid-cols-2 gap-2">
      <mat-form-field>
        <mat-label>Phone Number</mat-label>
        <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'ca', 'mx']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          format="national"
          name="phoneNumber"
          [required]="true"
          [formControl]="phoneNumberControl"
        ></ngx-mat-intl-tel-input>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="driverName" autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" autocomplete="off" type="password" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Referral Code</mat-label>
        <input matInput formControlName="referralCode" autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>MC Number</mat-label>
        <input matInput formControlName="mcNumber" autocomplete="off" />
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <div mat-dialog-actions class="flex flex-row justify-between">
    <button mat-flat-button color="accent" mat-dialog-close="">Cancel</button>
    <button mat-flat-button color="primary" (click)="signup()">Signup</button>
  </div>
</ng-template>
